// @file All network requests used by dashboard store
import type { UserReason } from '@@/bits/account_setup_helper'
import { apiUrlInterpolate } from '@@/bits/api_url_interpolate'
import getCsrfToken from '@@/bits/csrf_token'
import { asciiSafeStringify } from '@@/bits/json_stringify'
import { transformCurrentUrl } from '@@/bits/location'
import type { LibraryMembershipRole, LibraryMembershipStatus } from '@@/enums'
import type { LearnVideo } from '@@/pinia/dash_learn_store'
import { fetchJson } from '@@/surface/api_fetch'
import type {
  BackpackPlanResult,
  ChangeCardUrl,
  ContentSafetySettings as ContentSafetySettingsPayload,
  ContentSafetySettingsUpdateApiRequest,
  DelinquentCardStatusResponse,
  DomainValidation,
  EmailAddressVerificationResult,
  EstimateLibrarySwitchingCostResult,
  EstimateTeamNeonDowngradeRefund,
  Folder as FolderType,
  FolderApiResponse,
  FolderId,
  GoogleAppLicensingCreateResponse,
  GoogleAppLicensingSettingsResponse,
  GoogleAppLicensingValidateResponse,
  Id,
  JsonApiResponse,
  Library as LibraryType,
  LibraryAnalytics as LibraryAnalyticsType,
  LibraryAttributeCheck,
  LibraryDowngradeToTeamNeonResult,
  LibraryId,
  LibraryInvitedUser,
  LibraryInviteLinks,
  LibraryInvoice as LibraryInvoiceType,
  LibraryMembership,
  LibraryMembershipId,
  LibraryOauthSettings as LibraryOauthSettingsType,
  LibrarySwitchResult,
  LibraryUserEmailDomain as LibraryUserEmailDomainType,
  NotificationSettingApiResponse,
  SamlConnectionApiResponse,
  SchoolLibraryPermission,
  Tenant as TenantType,
  TenantAnalytics as TenantAnalyticsType,
  TenantId,
  TenantInviteLinks as TenantInviteLinksType,
  TenantInviteLinksParams,
  TenantParams,
  UndoLibraryScheduledChangeResult,
  User as UserType,
  UserCamelCase,
  UserFollowApiResponse,
  UserGroupFolderApiResponse,
  UserId,
  UserLibrarySetting,
  UserLibrarySettingsApiResponse,
  VerificationCodePurpose,
  WallFollowApiResponse,
  WallGalleryTemplate,
  WallId,
  WallSuggestedTemplatesData,
} from '@@/types'
import type { Invoice as InvoiceType, JsonAPIResponse, JsonAPIUpdateRequest, Wall as WallType } from '@padlet/arvo'
import { fetchInvoices } from '@padlet/arvo'
import { fetchResponse, HTTPMethod } from '@padlet/fetch'

// Ensure this version is synced with `mozart/tests/playwright/helpers/dashboard_test.ts`
const API_VERSION = 5

class Invoice {
  public static async fetch(): Promise<JsonAPIResponse<InvoiceType>> {
    return await fetchInvoices()
  }
}

class LibraryPermissions {
  public static async fetch(id: LibraryId): Promise<JsonAPIResponse<SchoolLibraryPermission>> {
    return await fetchJson(`/api/1/libraries/${id}/school_settings`, {
      method: HTTPMethod.get,
    })
  }

  public static async update(id: LibraryId, librarySettings): Promise<JsonAPIResponse<SchoolLibraryPermission>> {
    return await fetchJson(`/api/1/libraries/${id}/school_settings`, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ data: { attributes: { settings: librarySettings } } }),
    })
  }
}

class LibraryBilling {
  /**
   * Fetch library billing information.
   */
  public static async fetch({ libraryId }: { libraryId: LibraryId }, fetchOptions = {}): Promise<JsonAPIResponse<any>> {
    return await fetchJson(`/api/${API_VERSION}/libraries/${libraryId}/billing`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  /**
   * Cancel library subscription.
   */
  public static async cancelSubscription(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryDowngradeToTeamNeonResult>> {
    return await fetchJson(`/api/${API_VERSION}/libraries/${libraryId}/billing/cancel`, {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  /**
   * Fetch estimated downgrade to Team Neon refund.
   */
  public static async estimateTeamNeonDowngradeRefund(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<EstimateTeamNeonDowngradeRefund>> {
    return await fetchJson(`/api/${API_VERSION}/libraries/${libraryId}/billing/cancel/refund_estimate`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  /**
   * Fetch estimated downgrade to Team Neon refund.
   */
  public static async libraryChangeCardUrl(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<ChangeCardUrl>> {
    return await fetchJson(`/api/${API_VERSION}/libraries/${libraryId}/billing/change_card_url`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  /**
   * Undo a scheduled downgrade to Team Neon.
   */
  public static async undoScheduledDowngradeToTeamNeon(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<UndoLibraryScheduledChangeResult>> {
    return await fetchJson(`/api/${API_VERSION}/libraries/${libraryId}/billing/undo_scheduled_cancellation`, {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  /**
   * Undo a scheduled crossgrade.
   */
  public static async undoScheduledCrossgrade(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<UndoLibraryScheduledChangeResult>> {
    return await fetchJson(`/api/${API_VERSION}/libraries/${libraryId}/billing/undo_scheduled_plan_switch`, {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async estimateSwitchingCost(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<EstimateLibrarySwitchingCostResult>> {
    return await fetchJson(`/api/${API_VERSION}/libraries/${libraryId}/billing/switch_plan/estimate_cost`, {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async switchPlan(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibrarySwitchResult>> {
    return await fetchJson(`/api/${API_VERSION}/libraries/${libraryId}/billing/switch_plan`, {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }
}

class LibraryInvoice {
  public static async fetch(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryInvoiceType>> {
    return await fetchJson(`/api/${API_VERSION}/libraries/${libraryId}/billing/invoices`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }
}

class Library {
  public static get libraryUrl(): string {
    return `/api/1/libraries?userId=\${ userId }&filter=\${ filter }`
  }

  public static buildUrl(url, options): string {
    return apiUrlInterpolate(url, options)
  }

  public static async fetchCreatableLibraries(
    { userId }: { userId: UserId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryType>> {
    return await fetchJson(this.buildUrl(this.libraryUrl, { userId, filter: 'wall_creatable' }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async fetchCreatableAndVisibleLibraries(
    { userId }: { userId: UserId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryType>> {
    return await fetchJson(this.buildUrl(this.libraryUrl, { userId, filter: 'wall_creatable_and_visible' }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async fetchViewableLibraries(
    { userId }: { userId: UserId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryType>> {
    return await fetchJson(this.buildUrl(this.libraryUrl, { userId, filter: 'all_walls_viewable' }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async fetchViewableAndVisibleLibraries(
    { userId }: { userId: UserId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryType>> {
    return await fetchJson(this.buildUrl(this.libraryUrl, { userId, filter: 'all_walls_viewable_and_visible' }), {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async delete(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryType>> {
    return await fetchJson(`/api/${API_VERSION}/libraries/${libraryId}`, {
      method: HTTPMethod.delete,
      ...fetchOptions,
    })
  }

  public static async checkNameAndSlug(name: string, slug: string): Promise<JsonAPIResponse<LibraryAttributeCheck>> {
    return await fetchJson(`/api/1/libraries/check-name-and-slug`, {
      method: HTTPMethod.get,
      query: { name, slug },
    })
  }
}

class LibraryInfo {
  public static async fetch(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryType>> {
    return await fetchJson(`/api/1/libraries/${libraryId}/info`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async update(
    {
      libraryId,
      updateAttributes,
    }: { libraryId: LibraryId; updateAttributes: { avatar?: string; name?: string; slug?: string; bio?: string } },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryType>> {
    return await fetchJson(`/api/1/libraries/${libraryId}/info`, {
      method: HTTPMethod.patch,
      jsonData: {
        data: {
          attributes: updateAttributes,
        },
      },
      ...fetchOptions,
    })
  }
}

class LibraryUserEmailDomain {
  public static async fetch(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryUserEmailDomainType>> {
    return await fetchJson(`/api/1/libraries/${libraryId}/user_domains`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }
}

class LibraryOauthSettings {
  public static async fetch(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryOauthSettingsType>> {
    return await fetchJson(`/api/1/libraries/${libraryId}/oauth-settings`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async update(
    {
      libraryId,
      oauthMethod,
      enable,
      thirdPartyIdInput,
    }: { libraryId: LibraryId; oauthMethod: string; enable: boolean; thirdPartyIdInput?: string },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryOauthSettingsType>> {
    return await fetchJson(`/api/1/libraries/${libraryId}/oauth-settings`, {
      method: HTTPMethod.patch,
      jsonData: {
        oauthProvider: oauthMethod,
        enable,
        thirdPartyId: thirdPartyIdInput,
      },
      ...fetchOptions,
    })
  }

  public static async fetchGoogleAppLicensing(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<GoogleAppLicensingSettingsResponse>> {
    return await fetchJson(`/api/1/libraries/${libraryId}/google-app-licensing-settings`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async updateGoogleAppLicensing(
    {
      libraryId,
      enable,
      orderId,
      redeemUri,
    }: { libraryId: LibraryId; enable: boolean; orderId?: string; redeemUri?: string },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryOauthSettingsType>> {
    return await fetchJson(`/api/1/libraries/${libraryId}/google-app-licensing-settings`, {
      method: HTTPMethod.patch,
      jsonData: {
        enable,
        order_id: orderId,
        redeem_uri: redeemUri,
      },
      ...fetchOptions,
    })
  }
}

class LibraryMembers {
  public static async fetchMemberships(
    { libraryId, next }: { libraryId: LibraryId; next: string },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryMembership>> {
    const url = transformCurrentUrl(
      {},
      {
        path: '/api/1/libraries/memberships',
        search: {
          library_id: String(libraryId),
          next,
        },
      },
    )
    return await fetchJson(url, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async updateMembership(
    { id, role, status }: { id: LibraryMembershipId; role: LibraryMembershipRole; status: LibraryMembershipStatus },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryMembership>> {
    return await fetchJson('/api/1/libraries/memberships', {
      method: HTTPMethod.put,
      jsonData: {
        data: {
          attributes: {
            id,
            role,
            status,
          },
        },
      },
      ...fetchOptions,
    })
  }

  public static async changeOwner(
    { libraryId, userId }: { libraryId: LibraryId; userId: UserId },
    fetchOptions = {},
  ): Promise<void> {
    return await fetchJson(`/api/1/libraries/${libraryId}/change_owner`, {
      method: HTTPMethod.post,
      jsonData: {
        userId,
      },
      ...fetchOptions,
    })
  }

  public static async leaveLibrary({ libraryId }: { libraryId: LibraryId }, fetchOptions = {}): Promise<void> {
    return await fetchJson('/api/1/libraries/leave', {
      method: HTTPMethod.post,
      jsonData: {
        libraryId,
      },
      ...fetchOptions,
    })
  }

  public static async fetchLibraryInviteLinks(
    { libraryId }: { libraryId: LibraryId },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryInviteLinks>> {
    return await fetchJson(`/api/1/libraries/invite_links?library_id=${libraryId}`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async toggleInviteLink(
    updateAttributes: {
      libraryId: LibraryId
      codeForAdminEnabled?: boolean
      codeForMakerEnabled?: boolean
      codeForContributorEnabled?: boolean
    },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryInviteLinks>> {
    return await fetchJson('/api/1/libraries/invite-links', {
      method: HTTPMethod.patch,
      jsonData: {
        data: {
          attributes: updateAttributes,
        },
      },
      ...fetchOptions,
    })
  }

  public static async search(
    {
      libraryId,
      query,
      next,
    }: {
      libraryId: LibraryId
      query: string
      next: string
    },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<LibraryMembership>> {
    const url = transformCurrentUrl(
      {},
      {
        path: '/api/1/libraries/memberships/search',
        search: {
          library_id: String(libraryId),
          q: query,
          next,
        },
      },
    )
    return await fetchJson(url, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async sendEmailInvites(
    { libraryId, emailRows }: { libraryId: LibraryId; emailRows: Array<{ email: string; role: string }> },
    fetchOptions = {},
  ): Promise<void> {
    return await fetchJson(`/api/1/libraries/${libraryId}/memberships/send-email-invites`, {
      method: HTTPMethod.post,
      jsonData: {
        data: {
          attributes: {
            invited_users: emailRows,
          },
        },
        ...fetchOptions,
      },
    })
  }

  public static async csvBulkAddMembers(
    { libraryId, InvitedUsersList }: { libraryId: LibraryId; InvitedUsersList: LibraryInvitedUser[] },
    fetchOptions = {},
  ): Promise<void> {
    return await fetchJson(`/api/1/libraries/${libraryId}/memberships/csv-bulk-add`, {
      method: HTTPMethod.post,
      jsonData: {
        data: {
          attributes: {
            invited_users: InvitedUsersList,
          },
        },
      },
      ...fetchOptions,
    })
  }
}

class BackpackPlan {
  /**
   * Fetch list of available backpack plans for current user (respective to the user's country code/currency)
   */
  public static async fetch(fetchOptions = {}): Promise<JsonAPIResponse<BackpackPlanResult>> {
    return await fetchJson(`/api/${API_VERSION}/backpack_plans`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }
}

class User {
  public static async sendResetPasswordEmail(fetchOptions = {}): Promise<JsonAPIResponse<any>> {
    return await fetchJson('/api/1/send_reset_password', {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async updatePassword(fetchOptions = {}): Promise<JsonAPIResponse<UserType>> {
    return await fetchJson(`/api/1/dashboard_settings/password`, {
      method: HTTPMethod.put,
      ...fetchOptions,
    })
  }

  public static async fetchSettingsUserInfo(fetchOptions = {}): Promise<JsonAPIResponse<UserCamelCase>> {
    return await fetchJson(`/api/1/dashboard_settings/user`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async updateSettingsUserInfo(fetchOptions = {}): Promise<JsonAPIResponse<UserType>> {
    return await fetchJson(`/api/1/dashboard_settings/user`, {
      method: HTTPMethod.patch,
      ...fetchOptions,
    })
  }

  public static async removeGoogleDriveAuthorization(id: UserId, fetchOptions = {}): Promise<{}> {
    return await fetchJson(`/api/1/user/${id}/user_integrations/google_drive`, {
      method: HTTPMethod.delete,
      ...fetchOptions,
    })
  }

  public static async updateUserReason(
    id: UserId,
    reason: UserReason,
  ): Promise<JsonAPIResponse<{ reason: UserReason }>> {
    return await fetchJson(`/api/1/users/${id}/reason_for_using_padlet`, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ data: { attributes: { reason } } }),
    })
  }

  public static async updateBasicInfo(id: UserId, fetchOptions = {}): Promise<UserType> {
    return await fetchJson(`/users/${id}`, {
      method: HTTPMethod.put,
      ...fetchOptions,
    })
  }

  public static async resetPassword(id: UserId, token: string, newPassword: string): Promise<UserType> {
    return await fetchJson(`/users/${id}/change_password`, {
      method: HTTPMethod.put,
      body: asciiSafeStringify({ token, new_password: newPassword }),
    })
  }

  public static async requestResetPassword(payload: { email?: string; username?: string }): Promise<UserType> {
    return await fetchJson(`/api/${API_VERSION}/request-reset-password`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify(payload.email != null ? { email: payload.email } : { username: payload.username }),
    })
  }

  public static async getApiToken(fetchOptions = {}): Promise<JsonAPIResponse<any>> {
    return await fetchJson(`/api/1/public_api/tokens`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async generateApiToken(fetchOptions = {}): Promise<JsonAPIResponse<any>> {
    return await fetchJson(`/api/1/public_api/tokens`, {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async refreshApiToken(token: string | null): Promise<JsonAPIResponse<any>> {
    return await fetchJson(`/api/1/public_api/tokens/refresh`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ token }),
    })
  }

  public static async canIAccessApi(fetchOptions = {}): Promise<JsonAPIResponse<any>> {
    return await fetchJson(`/api/1/public_api/auth`, {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }

  public static async fetchUserLibrarySettings(): Promise<JsonAPIResponse<UserLibrarySettingsApiResponse>> {
    return await fetchJson('/api/1/dashboard_settings/user_library_settings', {
      method: HTTPMethod.get,
    })
  }

  public static async updateUserLibrarySetting({
    libraryId,
    visible,
  }: {
    libraryId: UserLibrarySetting['libraryId']
    visible: UserLibrarySetting['visible']
  }): Promise<JsonAPIResponse<UserLibrarySettingsApiResponse>> {
    return await fetchJson('/api/1/dashboard_settings/user_library_settings', {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ data: { attributes: { librarySetting: { libraryId, visible } } } }),
    })
  }

  public static async updateUserDefaultLibrary(
    defaultLibrary: UserLibrarySetting['libraryId'],
  ): Promise<JsonAPIResponse<UserLibrarySettingsApiResponse>> {
    return await fetchJson('/api/1/dashboard_settings/user_library_settings/default_library', {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ data: { attributes: { defaultLibrary } } }),
    })
  }

  public static async updateUserFeatureFlags(params: {
    userToggleKey: string
    userToggleValue: boolean
  }): Promise<void> {
    return await fetchJson('api/1/feature-flags/update', {
      method: HTTPMethod.post,
      body: asciiSafeStringify(params),
    })
  }
}

class Tenant {
  public static async updateTenantInfo(id: TenantId, fetchOptions: Partial<TenantParams>): Promise<TenantType> {
    const body: string = asciiSafeStringify(fetchOptions)
    return await fetchJson(`/tenants/${id}`, {
      method: HTTPMethod.put,
      body,
    })
  }

  public static async isSubdomainValid(subdomain: string): Promise<DomainValidation> {
    return await fetchJson(
      transformCurrentUrl(
        {},
        {
          path: '/tenants/subdomain_valid',
          search: { subdomain },
        },
      ),
      {
        method: HTTPMethod.get,
      },
    )
  }
}

class TenantInviteLinks {
  public static async get(id: TenantId): Promise<JsonAPIResponse<TenantInviteLinksType>> {
    return await fetchJson(`/api/tenants/${id}/invite_links`, {
      method: HTTPMethod.get,
    })
  }

  public static async patch(
    id: TenantId,
    fetchOptions: JsonAPIUpdateRequest<Partial<TenantInviteLinksParams>>,
  ): Promise<JsonAPIResponse<TenantInviteLinksType>> {
    const body: string = asciiSafeStringify(fetchOptions)
    return await fetchJson(`/api/tenants/${id}/invite_links`, {
      method: HTTPMethod.patch,
      body,
    })
  }
}

class TenantPermissions {
  public static async fetch(id: TenantId): Promise<JsonAPIResponse<TenantType>> {
    return await fetchJson(`/api/1/tenants/${id}/permissions`, {
      method: HTTPMethod.get,
    })
  }

  public static async update(id: TenantId, tenant: Partial<TenantType>): Promise<JsonAPIResponse<TenantType>> {
    return await fetchJson(`/api/1/tenants/${id}/permissions`, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ data: { type: 'tenant', id: tenant.id, attributes: tenant } }),
    })
  }
}

class TenantAnalytics {
  public static async fetch(id: TenantId): Promise<JsonAPIResponse<TenantAnalyticsType>> {
    return await fetchJson(`/api/${API_VERSION}/tenants/${id}/analytics`, {
      method: HTTPMethod.get,
    })
  }
}

class LibraryAnalytics {
  public static async fetch(id: TenantId): Promise<JsonAPIResponse<LibraryAnalyticsType>> {
    return await fetchJson(`/api/${API_VERSION}/libraries/${id}/analytics`, {
      method: HTTPMethod.get,
    })
  }
}

class Lti {
  public static async generateLtiDynamicRegistrationUrl(id: TenantId): Promise<{ url: string; tenantId: number }> {
    return await fetchJson(`/api/lti/dynamic_registration_url?id=${id}`, {
      method: HTTPMethod.get,
    })
  }

  public static async generateLtiLinkDeploymentToken(id: TenantId): Promise<{ token: string; tenantId: number }> {
    return await fetchJson(`/api/lti/link_deployment_token?id=${id}`, {
      method: HTTPMethod.get,
    })
  }

  public static async updateLtiUserGroupSyncEnabled(
    tenantId: TenantId,
    enabled: boolean,
  ): Promise<JsonAPIResponse<LibraryType>> {
    return await fetchJson(`/api/lti/settings/user-group-sync?id=${tenantId}`, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ enabled }),
    })
  }
}

class LibraryLti {
  public static async generateLtiDynamicRegistrationUrl(
    libraryId: LibraryId,
  ): Promise<{ url: string; libraryId: number }> {
    return await fetchJson(`/api/library-lti/dynamic-registration-url?id=${libraryId}`, {
      method: HTTPMethod.get,
    })
  }

  public static async generateLtiLinkDeploymentToken(
    libraryId: LibraryId,
  ): Promise<{ token: string; organizationType: string; organizationId: number }> {
    return await fetchJson(`/api/library-lti/link-deployment-token?id=${libraryId}`, {
      method: HTTPMethod.get,
    })
  }

  public static async updateLtiUserGroupSyncEnabled(
    libraryId: LibraryId,
    enabled: boolean,
  ): Promise<JsonAPIResponse<LibraryType>> {
    return await fetchJson(`/api/library-lti/settings/user-group-sync?id=${libraryId}`, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ enabled }),
    })
  }
}

class NotificationsSettings {
  public static async fetch(): Promise<JsonAPIResponse<NotificationSettingApiResponse>> {
    return await fetchJson(`/api/${API_VERSION}/notification_settings/general`, {
      method: HTTPMethod.get,
    })
  }

  public static async update(fetchOptions = {}): Promise<JsonAPIResponse<NotificationSettingApiResponse>> {
    return await fetchJson(`/api/${API_VERSION}/notification_settings/general`, {
      method: HTTPMethod.patch,
      ...fetchOptions,
    })
  }
}

class UserFollows {
  public static async fetch({
    pageNumber,
    pageSize,
  }: {
    pageNumber: number
    pageSize: number
  }): Promise<JsonAPIResponse<UserFollowApiResponse>> {
    return await fetchJson(
      transformCurrentUrl(
        {},
        {
          path: `/api/${API_VERSION}/notification_settings/user_follows`,
          search: { 'page[size]': String(pageSize), 'page[number]': String(pageNumber) },
        },
      ),
      {
        method: HTTPMethod.get,
      },
    )
  }

  public static async create(fetchOptions = {}): Promise<{ success: boolean }> {
    return await fetchJson('/api/user_follows.json', {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async delete(fetchOptions = {}): Promise<{ success: boolean }> {
    return await fetchJson('/api/user_follows.json', {
      method: HTTPMethod.delete,
      ...fetchOptions,
    })
  }
}

class WallFollows {
  public static async fetch({
    pageNumber,
    pageSize,
  }: {
    pageNumber: number
    pageSize: number
  }): Promise<JsonAPIResponse<WallFollowApiResponse>> {
    return await fetchJson(
      transformCurrentUrl(
        {},
        {
          path: `/api/${API_VERSION}/notification_settings/wall_follows`,
          search: { 'page[size]': String(pageSize), 'page[number]': String(pageNumber) },
        },
      ),
      {
        method: HTTPMethod.get,
      },
    )
  }

  public static async create(fetchOptions = {}): Promise<JsonAPIResponse<WallFollowApiResponse>> {
    return await fetchJson(`/api/${API_VERSION}/notification_settings/wall_follows`, {
      method: HTTPMethod.post,
      ...fetchOptions,
    })
  }

  public static async update(
    { wallFollowId }: { wallFollowId: Id },
    fetchOptions = {},
  ): Promise<JsonAPIResponse<WallFollowApiResponse>> {
    return await fetchJson(`/api/${API_VERSION}/notification_settings/wall_follows/${String(wallFollowId)}`, {
      method: HTTPMethod.patch,
      ...fetchOptions,
    })
  }
}

class Wall {
  public static async archive(wallId: number): Promise<JsonAPIResponse<WallType>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}/archive`, {
      method: HTTPMethod.put,
    })
  }

  public static async unarchive(wallId: number): Promise<JsonAPIResponse<WallType>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}/unarchive`, {
      method: HTTPMethod.put,
    })
  }

  public static async delete(wallId: number): Promise<JsonAPIResponse<WallType>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}`, {
      method: HTTPMethod.delete,
    })
  }

  public static async trash(wallId: WallId): Promise<JsonAPIResponse<WallType>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}/trash`, {
      method: HTTPMethod.patch,
    })
  }

  public static async untrash(wallId: WallId): Promise<JsonAPIResponse<WallType>> {
    return await fetchJson(`/api/${API_VERSION}/walls/${wallId}/untrash`, {
      method: HTTPMethod.patch,
    })
  }

  public static async permanentlyDelete(wallId: WallId): Promise<JsonAPIResponse<WallType>> {
    return await fetchJson(`/api/8/walls/${wallId}/delete`, {
      method: HTTPMethod.delete,
    })
  }

  public static async removeFromRecents(wallId: WallId): Promise<Response> {
    return await fetchResponse(`/api/1/walls/${wallId}/remove_from_recents`, {
      method: HTTPMethod.delete,
      headers: {
        'X-CSRF-Token': getCsrfToken(),
      },
    })
  }

  public static async removeBookmark(params: {
    folderId: FolderId | null
    wallId: WallId
  }): Promise<JsonAPIResponse<WallType>> {
    return await fetchJson('/api/1/bookmarks', {
      method: HTTPMethod.delete,
      jsonData: params,
    })
  }

  public static async addBookmark(params: {
    folderId: FolderId | null
    wallId: WallId
  }): Promise<JsonAPIResponse<WallType>> {
    return await fetchJson('/api/1/bookmarks', {
      method: HTTPMethod.post,
      jsonData: params,
    })
  }

  public static async transfer(params: { wallId: WallId; destinationLibraryId: LibraryId | null }): Promise<void> {
    return await fetchJson('api/1/walls/transfer', {
      method: HTTPMethod.post,
      jsonData: params,
    })
  }
}

class WallTemplate {
  public static async fetchWallTemplates(libraryId?: LibraryId): Promise<JsonAPIResponse<WallTemplate>> {
    const searchParams: Record<string, string> = {}
    if (libraryId !== undefined) {
      searchParams.library_id = String(libraryId)
    }
    const url = transformCurrentUrl(
      {},
      {
        path: `/api/${API_VERSION}/walls/templates`,
        search: searchParams,
      },
    )
    return await fetchJson(url, {
      method: HTTPMethod.get,
    })
  }
}

class DashStartingStateApi {
  public static async fetchGalleryTemplates(): Promise<JsonAPIResponse<WallGalleryTemplate>> {
    return await fetchJson(`/api/${API_VERSION}/dashboard/starting-state/gallery-templates`, {
      method: HTTPMethod.get,
    })
  }

  public static async fetchSuggestedTemplatesData(): Promise<JsonAPIResponse<WallSuggestedTemplatesData>> {
    return await fetchJson(`/api/${API_VERSION}/dashboard/starting-state/gallery-templates/curated`, {
      method: HTTPMethod.get,
    })
  }

  public static async fetchPadletLearnVideos(): Promise<JsonAPIResponse<LearnVideo[]>> {
    return await fetchJson(`/api/${API_VERSION}/dashboard/starting-state/learn-videos`, {
      method: HTTPMethod.get,
    })
  }
}

class Folder {
  public static async fetch(): Promise<JsonAPIResponse<FolderApiResponse>> {
    return await fetchJson(`/api/${API_VERSION}/folders`, {
      method: HTTPMethod.get,
    })
  }

  public static async create(attributes: Pick<FolderType, 'name'>): Promise<JsonAPIResponse<FolderType>> {
    return await fetchJson(`/api/${API_VERSION}/folders`, {
      method: HTTPMethod.post,
      jsonData: {
        data: {
          type: 'folder',
          attributes,
        },
      },
    })
  }

  public static async delete(folderId: FolderType['id']): Promise<JsonAPIResponse<FolderType>> {
    return await fetchJson(`/api/${API_VERSION}/folders/${folderId}`, {
      method: HTTPMethod.delete,
    })
  }

  public static async update(
    folderId: FolderType['id'],
    attributes: Pick<FolderType, 'name'>,
  ): Promise<JsonAPIResponse<FolderType>> {
    return await fetchJson(`/api/${API_VERSION}/folders/${folderId}`, {
      method: HTTPMethod.patch,
      jsonData: {
        data: {
          type: 'folder',
          attributes,
        },
      },
    })
  }
}

class EmailAddressVerification {
  public static async validateEmailAndSendVerificationCode(email: string): Promise<Response> {
    return await fetchResponse('/api/auth/signup/check_email', {
      method: HTTPMethod.get,
      query: {
        email,
        change_email: '1',
      },
    })
  }

  public static async verifyEmailAddress(
    email: string,
    verificationCode: string,
    purpose: VerificationCodePurpose,
  ): Promise<EmailAddressVerificationResult> {
    return await fetchJson(`/api/${API_VERSION}/auth/email/verify`, {
      method: HTTPMethod.post,
      jsonData: {
        email_address: email,
        verification_code: verificationCode,
        purpose,
      },
    })
  }

  public static async resendVerificationCode(
    email: string,
    purpose: VerificationCodePurpose,
  ): Promise<EmailAddressVerificationResult> {
    return await fetchJson(`/api/${API_VERSION}/auth/email/resend_verification_code`, {
      method: HTTPMethod.post,
      jsonData: {
        email_address: email,
        purpose,
      },
    })
  }
}

class UserGroups {
  public static async fetchUserGroupFolders(
    libraryId?: LibraryId,
  ): Promise<JsonAPIResponse<UserGroupFolderApiResponse>> {
    const fetchOptions: any = {}
    if (libraryId != null) fetchOptions.query = { libraryId }
    return await fetchJson('/api/1/group-folders', {
      method: HTTPMethod.get,
      ...fetchOptions,
    })
  }
}

class LibraryContentSafetySettings {
  public static async fetchContentSafetySettings(
    libraryId: string,
  ): Promise<JsonAPIResponse<ContentSafetySettingsPayload>> {
    return await fetchJson(`/api/${API_VERSION}/libraries/${libraryId}/content-safety-settings`, {
      method: HTTPMethod.get,
    })
  }

  public static async updateContentSafetySettings(
    libraryId: string,
    attributes: ContentSafetySettingsUpdateApiRequest,
  ): Promise<JsonAPIResponse<ContentSafetySettingsPayload>> {
    return await fetchJson(`/api/${API_VERSION}/libraries/${libraryId}/content-safety-settings`, {
      method: HTTPMethod.patch,
      jsonData: {
        data: {
          attributes,
        },
      },
    })
  }
}

class TenantContentSafetySettings {
  public static async fetchContentSafetySettings(
    tenantId: string,
  ): Promise<JsonAPIResponse<ContentSafetySettingsPayload>> {
    return await fetchJson(`/api/${API_VERSION}/tenants/${tenantId}/content-safety-settings`, {
      method: HTTPMethod.get,
    })
  }

  public static async updateContentSafetySettings(
    tenantId: string,
    attributes: ContentSafetySettingsUpdateApiRequest,
  ): Promise<JsonAPIResponse<ContentSafetySettingsPayload>> {
    return await fetchJson(`/api/${API_VERSION}/tenants/${tenantId}/content-safety-settings`, {
      method: HTTPMethod.patch,
      jsonData: {
        data: {
          attributes,
        },
      },
    })
  }
}

class GoogleAppLicensingSettings {
  public static async createLicenses(
    libraryId?: LibraryId,
  ): Promise<JsonAPIResponse<GoogleAppLicensingCreateResponse>> {
    return await fetchJson(`api/${API_VERSION}/google-app-licensing/create`, {
      method: HTTPMethod.post,
      jsonData: {
        libraryId,
      },
    })
  }

  public static async checkLicense(): Promise<JsonAPIResponse<GoogleAppLicensingValidateResponse>> {
    return await fetchJson(`api/${API_VERSION}/google-app-licensing/validate-or-logout`, {
      method: HTTPMethod.get,
    })
  }

  public static async checkDomainForExistingTenants(): Promise<JsonAPIResponse<string[]>> {
    return await fetchJson(`api/${API_VERSION}/google-app-licensing/existing-tenants`, {
      method: HTTPMethod.get,
    })
  }
}

class DelinquentCardStatus {
  public static async fetchDelinquentCardStatus(): Promise<JsonAPIResponse<DelinquentCardStatusResponse>> {
    return await fetchJson(`/api/${API_VERSION}/billing/delinquent-card-status`, {
      method: HTTPMethod.get,
    })
  }
}

class Saml {
  public static async createSamlConnection(
    createAttributes: {
      idpMetadataUrl: string
      emailAttribute: string
      nameAttribute: string
      roleAttribute?: string
      connectionName?: string
      devMode?: boolean
      prespecifiedSpEntityId?: string
    },
    fetchOptions = {},
  ): Promise<JsonApiResponse<SamlConnectionApiResponse>> {
    return await fetchJson('/api/1/saml-connections', {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ data: { attributes: createAttributes } }),
      ...fetchOptions,
    })
  }

  public static async fetchSamlConnections(): Promise<JsonApiResponse<SamlConnectionApiResponse>> {
    return await fetchJson('/api/1/saml-connections', {
      method: HTTPMethod.get,
    })
  }

  public static async updateSamlConnection(
    samlIdpLinkId: Id,
    updateAttributes: {
      emailAttribute?: string
      nameAttribute?: string
      roleAttribute?: string
      connectionName?: string
      allowUpgradeToTeacherRole?: boolean
      devMode?: boolean
    },
  ): Promise<JsonAPIResponse<SchoolLibraryPermission>> {
    return await fetchJson(`/api/1/saml-connections/${samlIdpLinkId}`, {
      method: HTTPMethod.patch,
      body: asciiSafeStringify({ data: { attributes: updateAttributes } }),
    })
  }
}

class ClassInformation {
  public static async upsert(classInformation: string): Promise<JsonAPIResponse<Response>> {
    return await fetchJson(`/api/${API_VERSION}/class-info`, {
      method: HTTPMethod.post,
      body: asciiSafeStringify({ class_info: classInformation }),
    })
  }
}

export {
  BackpackPlan,
  ClassInformation,
  DashStartingStateApi,
  DelinquentCardStatus,
  EmailAddressVerification,
  Folder,
  GoogleAppLicensingSettings,
  Invoice,
  Library,
  LibraryAnalytics,
  LibraryBilling,
  LibraryContentSafetySettings,
  LibraryInfo,
  LibraryInvoice,
  LibraryLti,
  LibraryMembers,
  LibraryOauthSettings,
  LibraryPermissions,
  LibraryUserEmailDomain,
  Lti,
  NotificationsSettings,
  Saml,
  Tenant,
  TenantAnalytics,
  TenantContentSafetySettings,
  TenantInviteLinks,
  TenantPermissions,
  User,
  UserFollows,
  UserGroups,
  Wall,
  WallFollows,
  WallTemplate,
}
